import { useState } from "react";
import styles from "../../Styles/Itinerary.module.scss";
import picture_3 from "../../img/Israel/IPDay_3.jpg";
import picture_4 from "../../img/Israel/IPDay_4.jpg";
import picture_5 from "../../img/Israel/IPDay_5.jpg";
import picture_6 from "../../img/Israel/IPDay_6.jpg";
import picture_7 from "../../img/Israel/IPDay_7.jpg";
import picture_8 from "../../img/Israel/IPDay_8.jpg";
import picture_9 from "../../img/Israel/IPDay_9.jpg";
import picture_10 from "../../img/Israel/IPDay_10.jpg";
import picture_11 from "../../img/Israel/IPDay_11.jpg";
import picture_12 from "../../img/Israel/IPDay_12.jpeg";
import picture_13 from "../../img/Israel/IPDay_13.jpg";
import picture_14 from "../../img/Israel/IPDay_14.jpg";
import itinerary from "../../img/Israel/Israel_Petra_Tour_2025.pdf";
import { IoClose } from "react-icons/io5";

function PetraIsraelItinerary() {
  //For day 2

  const [boxState, setBoxState] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]);
  const open = function (i) {
    const copy = [...boxState];
    copy[i] = true;
    setBoxState(copy);
  };
  const close = function (i) {
    const copy = [...boxState];
    copy[i] = false;
    setBoxState(copy);
  };

  return (
    <div className="pageContainer">
      <p className="title">Holy Land Tour</p>
      <p className="secondaryTitle">October 15-29, 2025</p>
      <div className="printBox">
        <div className="print">
          <a href={itinerary} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>

      {/*Day 1 */}
      <div>
        <h2 className={styles.dayNumber}>Day 1, Wednesday, October 15</h2>
        <p className="text">Depart the USA</p>
      </div>
      {/*Day 2 */}
      <div>
        <h2 className={styles.dayNumber}>Day 2, Thuesday, October 16</h2>
        <p className="text">
          Arrive in Tel Aviv (overnight nearby airport).
          {/* <button
            className={styles.popUpBtn}
            onClick={() => {
              open(2);
            }}
          >
            Read more...
          </button> */}
        </p>
      </div>
      {/*Box 2*/}
      {/* <div
        className={styles.popUpBox}
        style={{ display: boxState[2] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(2);
            }}
          />
          <img className={styles.pictureFloat} src={picture_2} alt="Athens" />
          <p className="text">
            Arrive in Greece and travel the 30-minute drive into Athens. Get
            settle into our hotel and explore the surrounding area in the heart
            of Athens.
          </p>
        </div>
      </div> */}
      {/*Day 3 */}
      <div>
        <h2 className={styles.dayNumber}>Day 3, Friday, October 17</h2>
        <p className="text">
          Caesarea Maritima, Mt. Carmel, Megiddo (overnight in Tiberias).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(3);
            }}
          >
            Read more...
          </button>
        </p>
        {/*Box 3*/}
        <div
          className={styles.popUpBoxLong}
          style={{ display: boxState[3] ? "flex" : "none" }}
        >
          <div className={styles.innerBox}>
            <IoClose
              className={styles.icon}
              onClick={() => {
                close(3);
              }}
            />
            <img className={styles.pictureFloat} src={picture_3} alt="Athens" />
            <p className="text">
              Our tour begins at Caesarea Maritima, constructed by Herod the
              Great between 22 and 10 B.C. and rich in biblical significance.
              For five centuries, it served as the center of Roman
              administration in Palestine. Among the highlights of our visit are
              the beautifully reconstructed theater built by Herod and the
              remnants of his palace situated along the Mediterranean coast.
              This city is notably the backdrop for the story of Cornelius as
              described in Acts 10, and it is also where the Apostle Paul
              endured two years of imprisonment.
            </p>
            <p className="text">
              Following this, we will ascend to the summit of Mount Carmel, the
              site where Elijah confronted the prophets of Baal, offering us a
              stunning view of the Jezreel Valley.
            </p>
            <p className="text">
              Our final destination for the day will be Tel Megiddo, known as
              Armageddon, a site intrinsically linked to the “end times” and the
              ultimate confrontation between good and evil, as referenced in
              Revelation 16:16.
            </p>
          </div>
        </div>
      </div>
      {/*Day 4*/}
      <div>
        <h2 className={styles.dayNumber}>Day 4, Saturday, October 18</h2>
        <p className="text">
          Mt. Arbel, boat on the Sea of Galilee, the Jesus Boat Museum,
          Capernaum, Mt. of Beatitudes, Decapolis--east side of the Sea of
          Galilee (overnight in Tiberias).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(4);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 4*/}
      <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[4] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(4);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_4}
            alt="Mt. Olympas"
          />
          <p className="text">
            We will start our day by visiting Mount Arbel, which majestically
            overlooks the Sea of Galilee from the west. From this vantage point,
            we will gaze upon the very land where Jesus Christ dedicated much of
            His ministry and performed numerous miracles. This experience
            promises to be one of the highlights of our tour.
          </p>
          <p className="text">
            After our time on Arbel, we will set out on a boat ride on the
            historic waters of the Sea of Galilee. This significant lake is
            known for miracles performed by Jesus, including calming the storm
            (Luke 8:22-25) and walking on its surface (Mark 6:47-52).
          </p>
          <p className="text">
            We will also visit the Mount of Beatitudes, the site where Jesus
            delivered His most renowned teachings (Matthew 5-7). From this
            location, you will be treated to a breathtaking view of the Sea of
            Galilee.
          </p>
          <p className="text">
            During the three years of Jesus' ministry, He lived on the north
            side of the lake in the town of Capernaum. Here He performed many
            miracles, including several on the lake itself: stilling the storm
            (Luke 8:22-25) and walking on its water (Mark 6:47-52).
          </p>
          <p className="text">
            Finally, we will travel to the eastern side of the lake and ascend
            the mountain that was once home to the ancient town of Hippos, the
            first city of the Decapolis. This area is believed to be where the
            demoniac resided (Mark 5). It is also near the site where the pigs,
            possessed by demons, plunged into the Sea of Galilee.
          </p>
        </div>
      </div>
      {/*Day 5*/}
      <div>
        <h2 className={styles.dayNumber}>Day 5, Sunday, October 19</h2>
        <p className="text">
          Tel Dan, Caesarea Philippi, the Golan Heights, Mt. Hermon, Nimrod
          Castle, Valley of Tears (overnight in Tiberias).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(5);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 5*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[5] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(5);
            }}
          />
          <img className={styles.pictureFloat} src={picture_6} alt="Philippi" />
          <p className="text">
            Throughout much of biblical history, Dan was recognized as the
            northernmost city within Israel's boundaries. The expression "from
            Dan to Beersheba" served as an idiomatic reference to the entirety
            of the nation. At Tel Dan, we will observe an impressive
            twenty-foot-high Canaanite gate made of sun-dried mud bricks,
            unearthed by archaeologists and referred to as "Abraham's Gate" from
            the Middle Bronze Age. Additionally, we will visit the site where
            Jeroboam I set up the golden calf, leading the ten northern tribes
            into apostasy.
          </p>
          <p className="text">
            Our journey will also take us to Caesarea Philippi, the location
            where Peter famously declared, “You are the Christ, the Son of the
            Living God,” affirming his belief in Jesus Christ. And then, as we
            ascend Mount Hermon, the highest peak in Israel, we will pause at
            Nimrod’s Castle, which offers a breathtaking view of the Hula Valley
            and northern Israel. Many scholars suggest that Mount Hermon is the
            site of the transfiguration of Jesus Christ.
          </p>
          <p className="text">
            Finally, we will visit the Valley of Tears, the site of a famous
            tank battle during the Yom Kippur War of 1973, where Israeli and
            Syrian forces clashed, and Israel won a decisive victory.{" "}
          </p>
        </div>
      </div>

      {/*Day 6*/}
      <div>
        <h2 className={styles.dayNumber}>Day 6, Monday, October 20</h2>
        <p className="text">
          Harod Springs (where Gideon chose his 300), Mt. Gilboa, Nazareth Brow,
          Nazareth Village (overnight in Tiberias).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(6);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 6*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[6] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(6);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_5}
            alt="Thessalonica"
          />
          <p className="text">
            At the foothills of Mount Gilboa lies Ein Harod, the spring of
            Harod, identified in Scripture as the site where God drastically
            reduced Gideon's army from 32,000 to 300 men. Today, the springs
            flow with cool, refreshing water from the base of the mountain. From
            this serene location, we will ascend to the summit of Mount Gilboa,
            where King Saul, the first king of Israel, met his fate at the hands
            of the Philistines.
          </p>
          <p className="text">
            Our journey will then take us to Nazareth, where we will enjoy
            stunning views of the Jezreel Valley from Nazareth Brow, the site of
            an attempt to push Jesus into the valley below. Following this, we
            will explore Nazareth Village, an open-air museum that vividly
            reconstructs and reenacts daily life in the Galilee during the time
            of Jesus.
          </p>
        </div>
      </div>
      {/*Day 7*/}
      <div>
        <h2 className={styles.dayNumber}>Day 7, Tuesday, October 21</h2>
        <p className="text">
          Jordan River (baptisms possible), Jericho, Qumran, the Dead Sea
          (overnight at Ein Bokek/the Dead Sea).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(7);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 7*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[7] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(7);
            }}
          />
          <img className={styles.pictureFloat} src={picture_7} alt="Philippi" />
          <p className="text">
            Departing from Galilee, we will head south towards Jericho, our
            first stop being Elisha&apos;s Spring and the historic site of Tel
            Jericho, recognized as the initial city conquered by Joshua and the
            Israelites upon their entrance into Canaan.
          </p>
          <p className="text">
            Following our visit, we will make a brief journey into the Judean
            Wilderness to Qumran, renowned for the discovery of the Dead Sea
            Scrolls. Here, you will have the opportunity to explore the remnants
            of the Essene settlement and gain insights into the meticulous
            practices of Essene scribes who copied the Old Testament scriptures
            and other ancient texts.
          </p>
          <p className="text">
            Our day will culminate with a scenic drive further south along the
            shoreline of the Dead Sea. We will spend the evening at a hotel
            located directly on the shores of the Dead Sea (the lowest spot on
            earth) in Ein Bokek, where you can indulge in the therapeutic
            mineral waters of this unique body of water.
          </p>
        </div>
      </div>
      {/*Day 8*/}
      <div>
        <h2 className={styles.dayNumber}>Day 8, Wednesday, October 22</h2>
        <p className="text">
          Masada, Jerusalem—visit the Old City—the Cardo, the Jewish Quarter,
          the Western Wall, the Jaffa Gate, the Zion Gate, the Western Wall of
          the Old City where Christ stood before Pontius Pilate and the crowd
          calling for His crucifixion (overnight in Jerusalem).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(8);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 8*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[8] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(8);
            }}
          />
          <img className={styles.pictureFloat} src={picture_8} alt="Meteora" />
          <p className="text">
            Our day will commence with a visit to the majestic mountain fortress
            of Masada, a remarkable site constructed by Herod the Great. We will
            ascend to the summit via cable car, where we will explore the ruins
            and take in panoramic views of the surrounding Judean Wilderness.
          </p>
          <p className="text">
            From the lowest elevation on Earth, situated 1,400 feet below sea
            level, we will then journey upward through the Judean Hills to reach
            Jerusalem, which is perched at an elevation of 2,500 feet above sea
            level. On this first day of our time in Israel&apos;s ancient
            capital, we will explore the Old City with its four quarters,
            dedicating significant time to the Western Wall of the Temple Mount,
            as well as the Western Wall of the Old City, a site where
            archaeologists believe Jesus stood before Pilate.
          </p>
        </div>
      </div>
      {/*Day 9*/}
      <div>
        <h2 className={styles.dayNumber}>Day 9, Thursday, October 23</h2>
        <p className="text">
          The Temple Mount, the Pool of Bethesda, Mt. Zion, the Upper Room, the
          grave of Oscar Schindler, the City of David, Hezekiah&apos;s Tunnel,
          the Pool of Siloam (overnight in Jerusalem).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(9);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 9*/}
      <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[9] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(9);
            }}
          />
          <img className={styles.pictureFloat} src={picture_10} alt="Corinth" />
          <p className="text">
            Our first destination today will be the Temple Mount, home of the
            iconic Dome of the Rock and the Al-Aqsa Mosque. This historic site
            was once the location of both Solomon&apos;s Temple and Herod&apos;s
            Temple. After exploring the expansive 35-acre platform of the Temple
            Mount, we will make a brief stop at the ruins of the Pool of
            Bethesda, where Jesus performed the miracle of healing the paralytic
            man, as recorded in the Gospel of John.
          </p>
          <p className="text">
            Next, we will ascend to Mount Zion to visit the Upper Room, the
            significant site where Jesus observed the Passover on the eve of his
            betrayal. While there, we will also pay our respects at the grave of
            Oscar Schindler, a member of the Nazi party who heroically saved
            over 1,200 Jews from the death camps by employing them in his
            enamelware and ammunition factories in occupied Poland.
          </p>
          <p className="text">
            Our day will culminate with a walk through Hezekiah’s Tunnel, which
            leads to the Pool of Siloam. This water conduit, constructed around
            700 B.C. under King Hezekiah's reign, connects the Gihon Spring to
            the Pool of Siloam.
          </p>
        </div>
      </div>

      {/*Day 10*/}
      <div>
        <h2 className={styles.dayNumber}>Day 10, Friday, October 24</h2>
        <p className="text">
          The Mt of Olives, the Pater Noster, the Garden of Gethsemane, the
          Kidron Valley, and the Garden Tomb (overnight in Jerusalem).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(10);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 10*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[10] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(10);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_9}
            alt="Meteora Monastery"
          />
          <p className="text">
            We will begin this day’s journey with a bus ride to the summit of
            the Mount of Olives, where we will spend time in the serene Pater
            Noster gardens. Here, we will reflect on Jesus’ teachings regarding
            prayer and forgiveness as outlined in Mark 11. This tranquil and
            picturesque location, situated near the site of Jesus' ascension to
            heaven, stands as one of the highlights of our trip.
          </p>
          <p className="text">
            As we descend the Mount of Olives, we will pause at various points
            to capture photographs of the Old City of Jerusalem. Our next stop
            will be the Garden of Gethsemane, where we encounter olive trees
            that may have roots dating back 2,000 years. The name "Gethsemane"
            translates to "olive press," a fitting designation for an area that
            has long been home to many olive trees.
          </p>
          <p className="text">
            Our exploration will proceed with a walk through the Kidron Valley,
            after which we will be transported to the Garden Tomb. Here, we will
            receive a guided tour of this significant site, believed by some to
            be the location of Christ Jesus' crucifixion, burial, and
            resurrection. The Garden Tomb features an ancient rock-cut tomb,
            serving as an important destination for Christian pilgrimage.
          </p>
        </div>
      </div>
      {/*Day 11*/}
      <div>
        <h2 className={styles.dayNumber}>Day 11, Saturday, October 25</h2>
        <p className="text">
          Bethlehem, Sde Boker: the burial place of David Ben Gurion and his
          wife (first prime minister of modern Israel), the Wilderness of Zin
          and Paran (where the children of Israel wandered), the Ramon Crater
          (overnight in Eilat).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(11);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 11*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[11] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(11);
            }}
          />
          <img className={styles.pictureFloat} src={picture_11} alt="Corinth" />
          <p className="text">
            Our day will commence with a journey to Bethlehem, located five
            miles south of Jerusalem. We will explore Manger Square and visit
            the Church of the Nativity, which is traditionally believed to be
            situated at the site of Jesus Christ's birth.
          </p>
          <p className="text">
            After our visit to Bethlehem, we will head south into the Negev
            Desert, making a stop at Sde Boker, the final resting place of David
            Ben Gurion and his wife. Ben Gurion was the first Prime Minister of
            modern Israel. From this location, we will enjoy an outstanding view
            of the Wilderness of Zin, where the children of Israel wandered.
          </p>
          <p className="text">
            As we proceed on our journey toward Eilat and the Red Sea, we will
            pause at the Ramon Crater overlook to take in the stunning views of
            this remarkable geological formation. The Ramon Crater holds the
            title of the world’s largest erosion crater, or makhtesh, with
            dimensions of twenty-five miles in length, six miles in width, and a
            depth of 2,400 feet.
          </p>
          <p className="text">
            In the evening, we will arrive at our hotel in Eilat. If time
            allows, we may dine at a restaurant situated by the shores of the
            Red Sea.
          </p>
        </div>
      </div>
      {/*Day 12*/}
      <div>
        <h2 className={styles.dayNumber}>Day 12, Sunday October 26</h2>
        <p className="text">
          Visit the replica of the Old Testament tabernacle at Timna Park and
          eat/swim at the Red Sea (overnight in Eilat).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(12);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 12*/}
      <div
        className={styles.popUpBox}
        style={{ display: boxState[12] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(12);
            }}
          />
          <img className={styles.pictureFloat} src={picture_13} alt="Corinth" />
          <p className="text">
            On this leisurely day, we will explore Timna Park, where we will
            take a tour of a replica of the Old Testament Tabernacle constructed
            to the precise dimensions of the original. Our guide will explain
            how the redemptive work of Jesus Christ is symbolized within the
            Tabernacle and its furnishings.
          </p>
          <p className="text">
            Later in the day, there will be an opportunity to swim in the Red
            Sea, stroll along its shoreline, and shop at the various boutiques
            located in Eilat.
          </p>
        </div>
      </div>

      {/*Day 13*/}
      <div>
        <h2 className={styles.dayNumber}>Day 13, Monday, October 27</h2>
        <p className="text">
          A day trip and tour to Petra in Jordan (overnight in Eilat).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(13);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 13*/}
      <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[13] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(13);
            }}
          />
          <img className={styles.pictureFloat} src={picture_12} alt="Corinth" />
          <p className="text">
            After being picked up from our hotel, we will cross the border into
            Jordan and head to Petra. This historical and archaeological city is
            renowned for its rock-cut architecture and sophisticated water
            conduit systems. Half-built and half-carved into the rock, Petra is
            surrounded by mountains filled with passages and gorges. It served
            as the capital of the Nabataean people for centuries and is
            affectionately known as the Rose City. Additionally, it is
            recognized as one of the world's most famous UNESCO World Heritage
            Sites.
          </p>
          <p className="text">
            Upon arrival, we will embark on a guided tour of Petra, followed by
            lunch. Afterward, we will have some free time to explore the site at
            our own pace before it is time to board our bus for the return to
            Eilat.
          </p>
        </div>
      </div>
      {/*Day 14*/}
      <div>
        <h2 className={styles.dayNumber}>Day 14, Tuesday, October 28</h2>
        <p className="text">
          Travel day from Eilat back to the area of the Ben Gurion airport with
          a stop at the Valley of Elah (David and Goliath), supper at Joppa as
          time permits (overnight near the airport).
          <button
            className={styles.popUpBtn}
            onClick={() => {
              open(14);
            }}
          >
            Read more...
          </button>
        </p>
      </div>
      {/*Box 14*/}
      <div
        className={styles.popUpBoxLong}
        style={{ display: boxState[14] ? "flex" : "none" }}
      >
        <div className={styles.innerBox}>
          <IoClose
            className={styles.icon}
            onClick={() => {
              close(14);
            }}
          />
          <img
            className={styles.pictureFloat}
            src={picture_14}
            alt="The Valley of Elah, Israel"
          />
          <p className="text">
            In the morning, we will begin our journey back north toward the
            vicinity of Ben Gurion Airport. Along the way, we will stop at the
            Valley of Elah, the historic site where David triumphed over
            Goliath. This location is a significant highlight of our trip, and
            our devotional time here will culminate with a challenge to place
            our trust in God when facing the giants in our lives.
          </p>
          <p className="text">
            In the evening, time permitting, we will dine in Joppa, the site
            where Dorcas was raised from the dead and where Peter received a
            pivotal vision on a rooftop that inspired him to share the Gospel
            with Cornelius and the Gentile community.
          </p>
        </div>
      </div>
      {/*Day 15*/}
      <div>
        <h2 className={styles.dayNumber}>Day 15, Wednesday, October 29</h2>
        <p className="text">
          The group will be transported to the airport and fly back to the USA.
        </p>
      </div>
    </div>
  );
}

export default PetraIsraelItinerary;
