import React, { useState } from "react";
import styles from "./../../Styles/VideoStudy.module.scss";
import VideoBox from "../../Components/VideoBox";

//Week 1
const videoList_1 = [
  {
    id: 1,
    title: "Mount of Olives",
    duration: "28:31 mins",
    sourceLink: "https://www.youtube.com/embed/cCNO8AfYyVI?si=3uBazuIwcdNGxmxx"
  },
  {
    id: 2,
    title: "The Temple Mount",
    duration: "28:38 mins",
    sourceLink: "https://www.youtube.com/embed/tba4u-aLB2s?si=ZWrpbJ3brhiQtBcV"
  },
  {
    id: 3,
    title: "Mount Zion",
    duration: "28:38 mins",
    sourceLink: "https://www.youtube.com/embed/e4-7wmE8ha4?si=XuhkGA_DR5Iz2g5o"
  },
  {
    id: 4,
    title: "Mount Herzl",
    duration: "28:32 mins",
    sourceLink: "https://www.youtube.com/embed/jmHIYCOkrws?si=0nooUewNmef5ldw_"
  },
  {
    id: 5,
    comments:
      "I love Jewish music. It is usually in a minor key and has a beauty to it that moves me. This is a favorite of mine done by a boys’ choir:",
    title: "Jerusalem Boys’ Choir—a song about the city of Jerusalem",
    duration: "5:58 mins",
    sourceLink: "https://www.youtube.com/embed/8NIxqHJrL68?si=IFA0KFc-KHkGG11O"
  },
  {
    id: 6,
    comments:
      "This is the first of several documentaries about biblical archaeology:",
    title: "Jesus Christ—The Top Ten Archaeological Discoveries",
    duration: "26:30 mins",
    sourceLink: "https://www.youtube.com/embed/Jt1ASX1Vi7w?si=cyNbYiisGJT2iVl_"
  }
];
//Week 2
const videoList_2 = [
  {
    id: 1,
    title: "History of Jerusalem in five minutes",
    duration: "5:15 mins",
    sourceLink: "https://www.youtube.com/embed/2mR2W43t6tI?si=XhzLc4S4r8Jq9GHe"
  },
  {
    id: 2,
    comments:
      "The following link for Part 1.  Part 2 should be showing on the side or below. When you click on it, then Part 3 will show…and so on:",
    title: "History of Jerusalem Parts 1-12 (10 min each-2 hrs total)",
    duration: "2 hr",
    sourceLink: "https://www.youtube.com/embed/Z0neFvYs1j0?si=yDmWacQ8t5_gjxjJ"
  },
  {
    id: 3,
    title: "(Boy’s choir) Shalom Alechem means “peace to you”)",
    duration: "4:20 mins",
    sourceLink: "https://www.youtube.com/embed/ux5TH9TPSMU?si=bjlxMkoK0bbhhrm5"
  },
  {
    id: 4,
    title: "Gates of Jerusalem",
    duration: "11:32 mins",
    sourceLink: "https://www.youtube.com/embed/J9cbEknMuMw?si=VqlaLyorkJHP3Azm"
  }
];

// Week 3
const videoList_3 = [
  {
    id: 1,
    title:
      "Top Ten Archaeological Findings That Authenticate the Bible. 11:56 mins",
    duration: "11:56 mins",
    sourceLink: "https://www.youtube.com/embed/Wo7CKYPFxDw?si=z95w1_M2UJQOrgsv"
  },
  {
    id: 2,
    comments:
      "Also, I am including a documentary on Jericho. This is a very interesting archaeology showing the accuracy of the biblical record:",
    title: "Secrets of the Bible: The Fall of Jericho with Dr. Bryant Wood.",
    duration: "55:20 mins",
    sourceLink: "https://www.youtube.com/embed/tZBl19bjmCs?si=x8Js6qfRI8OnqA7q"
  },

  {
    id: 3,
    title: "The Hebrew Curse Tablet found by archeologists from Houston",
    duration: "3:01 mins",
    sourceLink: "https://www.youtube.com/embed/Fo3ZlMVzEGU?si=3z2e9VG2062u2Ylf"
  },
  {
    id: 4,
    title:
      " Mt. Ebal “Curse Tablet” Discovery: Bigger Than the Dead Seas Scrolls?",
    duration: "17:27 mins",
    sourceLink: "https://www.youtube.com/embed/5bxPpZvFonc?si=70E-lrA2gNV4MNxa"
  },
  {
    id: 5,
    title: "Archaeological Find upends secular academia",
    duration: "37:43 mins",
    sourceLink: "https://www.youtube.com/embed/wWlKg9g1IXs?si=pjPeXLfDS-itUUpg"
  },

  {
    id: 6,
    title: "Top Ten Discoveries in 2021 in Biblical Archaeology",
    duration: "25:36 mins",
    sourceLink: "https://www.youtube.com/embed/PdDUv407hSM?si=I75oy2KKjnQx9kyy"
  },
  {
    id: 7,
    title: "40 Archaeology Facts that point to the accuracy of the Bible.",
    duration: "33:11 mins",
    sourceLink: "https://www.youtube.com/embed/-3eRyR03Y8Q?si=dQm3NHM5_xPF3urj"
  }
];

// Week 4

const videoList_4 = [
  {
    id: 1,
    title: "The 50 Year War Israel and Arabs, Part 1",
    duration: "2:25 hrs",
    sourceLink: "https://www.youtube.com/embed/fSAD9pS8NIw?si=fxAnz25xgiMDJ7AO"
  },
  {
    id: 2,
    title: "The 50-Year War Israel and Arabs, Part 2",
    duration: "2:26 hrs",
    sourceLink: "https://www.youtube.com/embed/MtLorIXCcz4?si=nCFkU-jPcKrC-JyA"
  }
];

// Week 5
const videoList_5 = [
  {
    id: 1,
    title: "Against All Odds",
    duration: "1 hr 37 mins",
    sourceLink: "https://www.youtube.com/embed/SsN5bqVzX34"
  }
];

// Week 6
const videoList_6 = [
  {
    id: 1,
    title: "Who was Herod the Great? A Short Introduction to Herod the Great",
    duration: "9:02 mins",
    sourceLink: "https://www.youtube.com/embed/Vs17NtcK5KQ?si=RNbQf3xikF_ms-fx"
  },
  {
    id: 2,
    title: "Herod the Great: A Biblical and Historical Profile",
    duration: "31:21 mins",
    sourceLink: "https://www.youtube.com/embed/B35_C-3lc4U?si=9rbyaim5ZSAogN7D"
  },
  {
    id: 3,
    comments:
      " I do not accept the doubt this presentation casts over the account of Matthew that Herod ordered the death of the children in Bethlehem. This event was very much in keeping with Herod’s paranoia about losing his throne. But the rest of the video is very informative.",
    title: "Secrets of Herod’s Reign",
    duration: "45:20 mins",
    sourceLink: "https://www.youtube.com/embed/LnQ-9fNw3xM?si=gok6s04OB3k8f_3d"
  },
  {
    id: 4,
    comments:
      " I do not accept the doubt this presentation casts over the account of Matthew that Herod ordered the death of the children in Bethlehem. This event was very much in keeping with Herod’s paranoia about losing his throne. But the rest of the video is very informative.",
    title: "Who Was the Real King Herod? | Biblical Tyrant | Timeline",
    duration: "48:26 mins",
    sourceLink: "https://www.youtube.com/embed/i_eJqsnPknM?si=ZI_fMgYzp9JFi0Wm"
  },
  {
    id: 5,
    title: "The Temple—Herod’s Crowning Glory—",
    duration: "14:50 mins",
    sourceLink: "https://www.youtube.com/embed/ElAMqb5Jc78?si=X-9Ot5FrZeqsGKqB"
  },
  {
    id: 6,
    title: "Herod the Great & His Building Projects",
    duration: "8:01 mins",
    sourceLink: "https://www.youtube.com/embed/o7OkvgwvncA?si=L7GATxao6iBCpFFT"
  },
  {
    id: 7,
    title: "Maccabees & King Herod Family Tree",
    duration: "15:21 mins",
    sourceLink: "https://www.youtube.com/embed/1osahsb_K2A?si=m-lI6CoyEJm3tr5R"
  },
  {
    id: 8,
    title: "Herodium (Herodion) - The Secret of King Herod's Hidden Tomb",
    duration: "7:09 mins",
    sourceLink: "https://www.youtube.com/embed/yOVUEiCsvNk?si=VIkmptA-K0J2OiwL"
  },
  {
    id: 9,
    title: "Who Are All the Herods in the Bible?",
    duration: "5:25 mins",
    sourceLink: "https://www.youtube.com/embed/J1eKZvx0WUs?si=da7XeC9hdtIT4IEV"
  }
];

// Week 7
const videoList_7 = [
  {
    id: 1,
    title: "Qumran: Dead Sea Scrolls: Mini Documentary (4 mins)",
    duration: "4 mins",
    sourceLink: "https://www.youtube.com/embed/xm3ItxQQ16E?si=ayEU9nMPEC_Ekqrt"
  },
  {
    id: 2,
    title:
      "The Watchman Episode 64: The Dead Sea Scrolls—Who Wrote Them and What They Mean",
    duration: "27:30 mins",
    sourceLink: "https://www.youtube.com/embed/K_qSn17DXXI?si=A4hiyTlP6zC7g6km"
  },
  {
    id: 3,
    title:
      " Is the Bible True and Reliable? Isaiah 53 and the Dead Sea Scrolls",
    duration: "5:28 mins",
    sourceLink: "https://www.youtube.com/embed/8bWojVoCKvk?si=k3y7oJ_5geOGdhOI"
  },
  {
    id: 4,
    title:
      "The Watchman Episode 88: Exploring Shiloh, Site of Israel’s Ancient Tabernacle",
    duration: "watch the first 15 minutes",
    sourceLink: "https://www.youtube.com/embed/ymwYnKizNi8?si=BY2Q3U8K6kvJl0kD"
  }
];

// Week 8
const videoList_8 = [
  {
    id: 1,
    title: "The Crusades: The Crescent and the Cross: Part One:",
    duration: "90 mins",
    sourceLink: "https://www.youtube.com/embed/ezP7Qv_9jl8?si=sYf57ZkGmh0_r92g"
  },
  {
    id: 2,
    title: "The Crusades: The Crescent and the Cross: Part Two:",
    duration: "90 mins",
    sourceLink: "https://www.youtube.com/embed/TchhrTzaP5A?si=x0biNYxblk0w-f--"
  }
];

// Week 9
const videoList_9 = [
  {
    id: 1,
    title: "What is Judaism",
    duration: "17:01 mins",
    sourceLink: "https://www.youtube.com/embed/0VAylWxDQfk?si=7sq0ZMgfZgS1tV1s"
  },
  {
    id: 2,
    title: "Jewish Tradition and Family Life",
    duration: "18:25 mins",
    sourceLink: "https://www.youtube.com/embed/G44-QtaZi2E?si=WMnbV2HY5K5My38f"
  },
  {
    id: 3,
    title: "Jewish Customs and Traditions",
    duration: "51:03 mins",
    sourceLink: "https://www.youtube.com/embed/pEpAmscnzm0?si=V0cHqadrXuzYWwKl"
  },
  {
    id: 4,
    title: "History of Jewish Movements: Reform, Conservative and Orthodox",
    duration: "5:25 mins",
    sourceLink: "https://www.youtube.com/embed/zUNpC9Vjftg?si=n0oF1nrcFSb31qi4"
  },
  {
    id: 5,
    title: "Jewish Traditions Explained",
    duration: "8:28 mins",
    sourceLink: "https://www.youtube.com/embed/hPwWgV6cuxQ?si=SxtdY4FNDs3f2DCL"
  },
  {
    id: 6,
    title: "Why Wear Fringes?",
    duration: "1:28 mins",
    sourceLink: "https://www.youtube.com/embed/dEQie7ybuBU?si=6swdm7iYeIbEEMc4"
  },
  {
    id: 7,
    title: "Why Do Jews Tie Strings at Their Waistline?",
    duration: "7:15 mins",
    sourceLink: "https://www.youtube.com/embed/9CrfFU8MN8k?si=AkslRZ_CdVU8S1-2"
  },
  {
    id: 8,
    title: "Why Jewish Men Wear Kippa and Tzitzit?",
    duration: "2:11 mins",
    sourceLink: "https://www.youtube.com/embed/wNFpuKxc628?si=PppPc675E7FWT5du"
  },
  {
    id: 9,
    title: "Why Do Jewish Women Wear Wigs",
    duration: "3:22 mins",
    sourceLink: "https://www.youtube.com/embed/9CrfFU8MN8k?si=1GsBJX8Zn_6V_arg"
  },
  {
    id: 10,
    title: "10 Surprising Facts about Judaism",
    duration: "6:31 mins",
    sourceLink: "https://www.youtube.com/embed/RAkUKo9BFcU?si=n-jH4jx23M0YywDu"
  },
  {
    id: 11,
    title: "How the Haredim, Israel’s ultra-Orthodox, make their own rules",
    duration: "14:07 mins",
    sourceLink: "https://www.youtube.com/embed/uGp4f2GnKTI?si=FPFHUsFMxbBxb0Xo"
  }
];

// Week 10
const videoList_10 = [
  {
    id: 1,
    title: " Prophetic Fulfilment of the Biblical Feasts",
    duration: "9 mins",
    sourceLink: "https://www.youtube.com/embed/BJ_oGL33OKc?si=2OSGGqTgTNJs__ns"
  },
  // {
  //   id: 2,
  //   title: "Caesarea Philippi: Gates of Hell—Ray VanderLaan—Matthew 16",
  //   duration: "18:12 mins",
  //   sourceLink: "",
  // },
  {
    id: 3,
    title:
      "Caesarea Philippi (Panias/Banias) Peter’s Confession of who Christ was!",
    duration: "13:56 mins",
    sourceLink: "https://www.youtube.com/embed/YYnUR_OxWKc?si=o6adMxV4nLiDdXeb"
  },
  {
    id: 4,
    title: "Why Jesus Moved from Nazareth to Capernaum",
    duration: "13:02 mins",
    sourceLink: "https://www.youtube.com/embed/3NaOUlRPz2Y?si=nOWrD_jDoq0JGlL_"
  },
  {
    id: 5,
    title: "The Bible Comes to Life: Caesarea",
    duration: "7:39 mins",
    sourceLink: "https://www.youtube.com/embed/c7msQn3mJzg?si=14n80i0-rNGbHqSi"
  }
];

// Week 11
const videoList_11 = [
  {
    id: 1,
    title: "01 Introduction. The Land of the Bible: Location and Land Bridge",
    duration: "5:28 mins",
    sourceLink: "https://www.youtube.com/embed/EzzqeBx937I?si=acaJR2ZJsEb6hiMZ"
  },
  {
    id: 2,
    title: "02 Land of the Bible: Basic Regions and Regional Variety",
    duration: "9:59 mins",
    sourceLink: "https://www.youtube.com/embed/Z3ltBtObmmQ?si=nYLEpLuW0bAP83x1"
  },
  {
    id: 3,
    title: "03 Major Routes in the Land of the Bible",
    duration: "6:10 mins",
    sourceLink: "https://www.youtube.com/embed/DH4PC9BBkLE?si=8BuOgMiKM7M45kQJ"
  },
  {
    id: 4,
    title: " 06 Jezreel Valley",
    duration: "14:20 mins",
    sourceLink: "https://www.youtube.com/embed/u0-GuClRMSU?si=yTHFgYJOm21g9MSW"
  },
  {
    id: 5,
    title: "08 Samaria",
    duration: "14:47 mins",
    sourceLink: "https://www.youtube.com/embed/Br0-supIjoo?si=DHEvOdTgegmsdNzo"
  },
  {
    id: 6,
    title: "12 Negev Part 1",
    duration: "10:34 mins",
    sourceLink: "https://www.youtube.com/embed/em8ebJDlr78?si=PZuRRhSA9jApS4dk"
  },
  {
    id: 7,
    title: " 13 Negev Part 2",
    duration: "12:11 mins",
    sourceLink: "https://www.youtube.com/embed/9tAOi4RvC8o?si=1OH1k3p2nFp1Dq97"
  }
];

// Week 12
const videoList_12 = [
  {
    id: 1,
    title: "Introduction to Galilee of Jesus",
    duration: "28:30 mins",
    sourceLink: "https://www.youtube.com/embed/El1iIJRMjuY?si=vW3_jg8NUXB_p2bk"
  },
  {
    id: 2,
    title: "The Nazareth of Jesus",
    duration: "28:30 mins",
    sourceLink: "https://www.youtube.com/embed/RO-iXZ99dTg?si=xqziixNUZ3VE-dnN"
  },
  {
    id: 3,
    title: "Miracles of Jesus in the Galilee",
    duration: "28:30 mins",
    sourceLink: "https://www.youtube.com/embed/pvFwLK83or4?si=23qODZX0wr0ZSf6M"
  },
  {
    id: 4,
    title: "Walk Through the Old City of Jerusalem",
    duration: "28:31 mins",
    sourceLink: "https://www.youtube.com/embed/8Zb5fhXZ2YY?si=_3PXzfewmzcycWvd"
  },
  {
    id: 5,
    title: "Messianic Judaism in Israel",
    duration: "28:30 mins",
    sourceLink: "https://www.youtube.com/embed/vgtEytTOSJY?si=9aVfll5Jv7vmtqEs"
  },
  {
    id: 6,
    title: " Israel’s Geographical Challenge",
    duration: "2:20 mins",
    sourceLink: "https://www.youtube.com/embed/93onRmj9guc?si=Kx29z6bQEa4sMVQB"
  }
];

// Week 13
const videoList_13 = [
  {
    id: 1,
    title: "The Siege of Jerusalem (70 AD) - The Great Jewish Revolt",
    duration: "44:51 mins",
    sourceLink: "https://www.youtube.com/embed/y741QbT1YEo?si=rjhdwZMHcPJmkaRG"
  },
  {
    id: 2,
    title:
      "The Siege of Masada (73 AD) - Last Stand of the Great Jewish Revolt",
    duration: "16 mins",
    sourceLink: "https://www.youtube.com/embed/11iPrDv8aBE?si=w2XmODFXRtDY4ICW"
  }
];

// Week 14
const videoList_14 = [
  {
    id: 1,
    title: "1948 The Birth of Israel and War of Independence",
    duration: "23:48 mins",
    sourceLink: "https://www.youtube.com/embed/CaIi-3huRLE?si=nRLfvRgrxnm96QgV"
  },
  {
    id: 2,
    title: "1967 Six-Day War",
    duration: "15:58 mins",
    sourceLink: "https://www.youtube.com/embed/hHqJ6pgdE-c?si=MFzxGixzirmGJaWy"
  },
  {
    id: 3,
    title: "1973 The Yom Kippur War",
    duration: "19:58 mins",
    sourceLink: "https://www.youtube.com/embed/dmDaB81MEQ0?si=lNDPyZFDiQ6o6Ttw"
  },
  {
    id: 4,
    title: "Yom Kippur war part 1 - Israel fights for her life and wins",
    duration: "First part 9:52 mins, total about 45 minutes",
    sourceLink: "https://www.youtube.com/embed/8vjRyw7YMfc?si=R_PWiBaSQHTi0Yit"
  }
];

// Week 15
const videoList_15 = [
  {
    id: 1,
    title: "Part 1: The Hope: Theodor Herzl",
    duration: "15:23 mins",
    sourceLink: "https://www.youtube.com/embed/XTsWXTlTp80?si=I0n2Bs-GyKEYkO-w"
  },
  {
    id: 2,
    title: "Part 2: The Hope: Buying the Land",
    duration: "12:28 mins",
    sourceLink: "https://www.youtube.com/embed/IQMZblqpvCE?si=VMAtYcj_qDYy2PF-"
  },
  {
    id: 3,
    title: "Part 3: The Hope: Chaim Weizmann",
    duration: "18:26 mins",
    sourceLink: "https://www.youtube.com/embed/iVe1-K9rvxw?si=qUHYoTsEsjqioQ59"
  },
  {
    id: 4,
    title: "Part 4: The Hope: David Ben-Gurion",
    duration: "20:34 mins",
    sourceLink: "https://www.youtube.com/embed/Cj_YhYJxGTM?si=GTNkOi2MxSCbzuwz"
  },
  {
    id: 5,
    title: "Part 5: The Hope: Golda Meir",
    duration: "19:05 mins",
    sourceLink: "https://www.youtube.com/embed/URNW21McORM?si=OjazfXD1_hylawFz"
  },
  {
    id: 6,
    title: "Part 6: The Hope: Independence Day",
    duration: "18:11 mins",
    sourceLink: "https://www.youtube.com/embed/2FHanxMIEJ0?si=-TX_6AzZ8_ajFIch"
  },
  {
    id: 7,
    title: "Part 7: The Hope: Eliezer Ben-Yehuda",
    duration: "15:43 mins",
    sourceLink: "https://www.youtube.com/embed/NQvB-djqMag?si=5wTFxXJSvGMGwHC9"
  },
  {
    id: 8,
    title: "Part 8: The Hope: Churchill and the Jews",
    duration: "17:31 mins",
    sourceLink: "https://www.youtube.com/embed/FDhy5uWPVDM?si=1iIJSwYnaWEue5M4"
  }
];

// Week 16
const videoList_16 = [
  {
    id: 1,
    title: "The origins of Israel and Palestinian conflict",
    duration: "10 mins",
    sourceLink: "https://www.youtube.com/embed/wb6IiSUxpgw?si=-_wOyd5Ei-Caz68t"
  },
  {
    id: 2,
    comments: "Pro-Jewish",
    title: "The MOST Important Video About Israel You'll Ever See!",
    duration: "13 mins",
    sourceLink: "https://www.youtube.com/embed/ydwxy9yqhzM?si=GpXVOuXqLuTStxRA"
  },
  {
    id: 3,
    title:
      "The Truth about Jerusalem- Danny Ayalon: Deputy Minister of Foreign Affairs",
    duration: "4 mins",
    sourceLink: "https://www.youtube.com/embed/Zz9CTBOKK4g?si=oeUSLcIXwRFWOwB3"
  },
  {
    id: 4,
    title:
      "Israel-Palestinian Conflict: The Truth About the West Bank – Danny Ayalon",
    duration: "4 mins",
    sourceLink: "https://www.youtube.com/embed/nc9imoJWgPI?si=rJtGrcv3aYy7arIF"
  },
  {
    id: 5,
    comments: "Pro-Palestinian",
    title: "The ACTUAL Truth About Palestine in response to Danny Ayalon",
    duration: "6:07 mins",
    sourceLink: "https://www.youtube.com/embed/MBYkBqY1-LM?si=Lf-EWJqGyuzcmHr0"
  },
  {
    id: 6,
    title: " Israel and Palestine 2014: War Explained!",
    duration: "6:27 mins",
    sourceLink: "https://www.youtube.com/embed/fW5EhSYOYCQ?si=44mpH0Zn7a-TzTJO"
  },
  {
    id: 7,
    title: "Interview with Jimmy Carter",
    duration: "5 mins",
    sourceLink: "https://www.youtube.com/embed/uvtC_qzHVM4?si=X3LCTX_5WjViXIz7"
  },
  {
    id: 8,
    comments: "Israel’s Settlements",
    title: "Israeli Settlements Explained, Part 1",
    duration: "8:05 mins",
    sourceLink: "https://www.youtube.com/embed/E0uLbeQlwjw?si=qt2uM_9sLZYc9Br2"
  },
  {
    id: 9,
    title: "Israeli Settlements Explained, Part 2",
    duration: "10:55 mins",
    sourceLink: "https://www.youtube.com/embed/B6L9mS9ti6o?si=6n-eDgdVnsunkc9w"
  }
];

const weeks = [
  "Week 1",
  "Week 2",
  "Week 3",
  "Week 4",
  "Week 5",
  "Week 6",
  "Week 7",
  "Week 8",
  "Week 9",
  "Week 10",
  "Week 11",
  "Week 12",
  "Week 13",
  "Week 14",
  "Week 15"
];

function VideoStudy() {
  const [modal, setModal] = useState(false);
  const [videoLink, setVideoLink] = useState("");
  //const [videoLoading, setVideoLoading] = useState(true);

  const [tabState, setTabState] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]);
  const changeVisiable = (day) => {
    const newVis = tabState.map((el, i) => {
      if (i === day) {
        return (el = true);
      } else {
        return (el = false);
      }
    });
    setTabState(newVis);
  };

  const togle = function (i) {
    const copy = [...tabState];
    if (copy[i] === false) {
      copy[i] = true;
    } else {
      copy[i] = false;
    }
    setTabState(copy);
  };

  const closeModal = () => {
    setModal(!modal);
  };
  const amazonFilm = {
    href: "https://www.amazon.com/Against-All-Odds-Survives-Questar/dp/B00UGQ8K8W",
    target: "_blank"
  };

  return (
    <div className="pageContainer">
      <div className="title">Documentaries to Prepare for Israel Trip</div>
      <p className="text">
        These documentaries cover a whole range of topics: Biblical archaeology,
        the history of Israel—both ancient and modern, site information,
        biblical geography, Judaism, the conflict between Arabs and Jews, etc.
      </p>
      <p className="text">
        Watching these documentaries will greatly enhance your learning in
        Israel. I strongly encourage you to take the time to watch them in the
        months ahead of departure. So much of what you will watch you will
        encounter in Israel. Watching these things will put you on second base,
        and you will not feel so inundated with information when you get to
        Israel.
      </p>
      <p className="text">
        I do not agree with everything that is mentioned in every video. If you
        hear something that “doesn’t sound right,” assume that I might disagree
        with it too. Be free to ask me questions about anything you hear.
      </p>
      <p className="text">
        Documentaries tend to come and go on YouTube. If any of these
        documentaries have been taken down for whatever reason, let me know. We
        can often substitute with something else.
      </p>
      <div className={styles.videoGroup}>
        {weeks.map((item, i) => (
          <p
            key={i}
            className={styles.videoGroupTitleIsrael}
            style={{
              color: tabState[weeks.indexOf(item)] ? "orange" : ""
            }}
            onClick={() => changeVisiable(i)}
          >
            {item}
          </p>
        ))}
      </div>
      {/* week 1 */}

      <div style={{ display: tabState[0] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          <p className="text">
            The first four videos are by David Reagan. He gives a lot of great
            information about the hills/mountains in Jerusalem. I am not sure
            that I always quite agree with some of the prophetic stuff that
            David says, but that aside, he presents so much that is very
            worthwhile hearing.s The eschatological material is not the main
            emphasis.
          </p>

          {videoList_1.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p className={styles.videoTime}>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 2 */}

      <div style={{ display: tabState[1] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          {videoList_2.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 3 */}

      <div style={{ display: tabState[2] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          <p className="text">
            This week we are studying biblical archaeology.
          </p>
          {videoList_3.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
          <h2 className={`secondaryTitle ${styles.subtitle}`}>
            Some other resources that are very interesting is a book and a DVD.
            You can order both on Amazon if you want them.
          </h2>
          <ul className={styles.bookList}>
            <li className="text">
              <span>Top Ten Biblical Discoveries in Archaeology</span> by
              Timothy G Kimberley. This resource is highly recommended.
            </li>
            <li className="text">
              <span>Patterns of Evidence: Exodus: A Filmmaker’s Journey</span>
              This is an excellent film. Well worth the time and money.
            </li>
          </ul>
        </div>
      </div>
      {/* Week 4 */}

      <div style={{ display: tabState[3] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          {videoList_4.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 5 */}

      <div style={{ display: tabState[4] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          <p className="text">
            This is a partial presentation of a series called Against All Odds
            that documents miracles that have taken place in Israel since 1948.
            It is an excellent resource and very inspiring. I would encourage
            you to purchase the series and watch as many of the accounts as
            possible. Here is the link to Amazon, where it can be purchased:
            <a {...amazonFilm} rel="norferrer" className={styles.link}>
              Click here
            </a>
          </p>
          {videoList_5.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}

              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 6 */}

      <div style={{ display: tabState[5] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          <p className="text">
            A study of Herod the Great is a fascinating study of a wicked ruler
            who was extremely rich and an outstanding builder. His building
            projects can still be seen all over Israel today. Taking time to
            discover who he was not only gives one a greater understanding of
            the Bible but a good introduction to what you will see in Israel.
            There is a lot of material here. Even if you don’t watch all of
            these, at least watch some.
          </p>
          {videoList_6.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 7 */}

      <div style={{ display: tabState[6] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          {videoList_7.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 8 */}

      <div style={{ display: tabState[7] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          <p className="text">
            The Crusades were a series of religious wars between Christians and
            Muslims that started primarily to secure control of holy sites
            considered sacred by both groups. In all, eight major Crusade
            expeditions occurred between 1096 and 1291. There are many remains
            of Crusader buildings in Israel to this day.
          </p>
          {videoList_8.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 9 */}

      <div style={{ display: tabState[8] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          {videoList_9.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 10 */}

      <div style={{ display: tabState[9] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          {videoList_10.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 11 */}

      <div style={{ display: tabState[10] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          {videoList_11.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 12 */}

      <div style={{ display: tabState[11] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          {videoList_12.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 13 */}

      <div style={{ display: tabState[12] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          {videoList_13.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 14 */}

      <div style={{ display: tabState[13] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          {videoList_14.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 15 */}

      <div style={{ display: tabState[14] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          {videoList_15.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {/* Week 16 */}

      <div style={{ display: tabState[15] ? "block" : "none" }}>
        <div className={styles.videoGroupBox}>
          <p className="text">
            This week, I want to expose you to the Palestinian/Jewish conflict
            over the land and the settlements. I want you to hear each side put
            forth their case.
          </p>
          {videoList_16.map((item, i) => (
            <div>
              {item.comments ? <p className="text">{item.comments}</p> : ""}
              <button
                onClick={() => {
                  setModal(!modal);
                  setVideoLink(item.sourceLink);
                }}
                className={styles.videoTitle}
                key={i}
              >
                {item.title}
                <p>{item.duration}</p>
              </button>
            </div>
          ))}
        </div>
      </div>

      {modal ? (
        <VideoBox closeModal={closeModal} videoLink={videoLink} />
      ) : null}
    </div>
  );
}

export default VideoStudy;
