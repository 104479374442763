import scripture from "./../../img/Israel/Scripture_Readings_Israel_Tour.pdf";
import styles from "../../Styles/ScriptureGuide.module.scss";
import { useState } from "react";

const reading = [
  "Friday, October 17",
  "Saturday, October 18",
  "Sunday, October 19",
  "Monday, October 20",
  "Tuesday, October 21",
  "Wednesday, October 22",
  "Thursday, October 23",
  "Friday, October 24",
  "Saturday, October 25",
  "Sunday, October 26",
  "Monday, October 27",
  "Tuesday, October 28"
];

const links = [
  {
    href: "https://israelinsideout.com/galilee-and-golan/the-valley-of-tears.html",
    target: "_blank"
  },
  {
    href: "https://en.wikipedia.org/wiki/Petra",
    target: "_blank"
  },
  {
    href: "https://www.lonelyplanet.com/jordan/petra/attractions/petra/a/poi-sig/1457302/1001785",
    target: "_blank"
  },
  {
    href: "https://www.biblicalarchaeology.org/daily/the-apostle-paul-in-arabia/",
    target: "_blank"
  }
];

function Scriptureguide() {
  const [visiable, setVisiable] = useState([
    true,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false
  ]);

  const changeVisiable = (day) => {
    const newVis = visiable.map((el, i) => {
      if (i === day) {
        return (el = true);
      } else {
        return (el = false);
      }
    });
    setVisiable(newVis);
  };

  return (
    <div className="pageContainer">
      <div className="title">Scripture Reading</div>
      <p className="secondaryTitle">Israel Tour</p>
      <div className="printBox">
        <div className="print">
          <a href={scripture} target="_blank" rel="noreferrer">
            Print PDF
          </a>
        </div>
      </div>
      <p className="text">
        This is a reading guide from the Bible and the recommended handbook. It
        corresponds with the itinerary.
      </p>
      <div className={styles.bookSection}>
        <p className={styles.book}>The Bible</p>
        <p className={styles.book}>
          The Holy Land for Christian Travelers: An Illustrated Guide to Israel,
          <span className={styles.bookOther}>
            John A. Beck. Baker Books, 2017. Available on Amazon for $16.99
            (paper) and $9.99 (kindle). Gives lots of information about the
            sites.
          </span>{" "}
          <span className={styles.bookComment}>
            (This book is highly recommended for understanding the sites in
            Israel.)
          </span>
        </p>
      </div>

      <div className={styles.readingDates}>
        {reading.map((el, i) => (
          <p
            key={i}
            className={styles.paragraphTitle}
            style={{
              color: visiable[reading.indexOf(el)] ? "orange" : ""
            }}
            onClick={() => changeVisiable(i)}
          >
            {el}
          </p>
        ))}
      </div>
      {/* Day 1 */}
      {visiable[0] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[0]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>Caesarea:</span> Acts 10, Acts
            12:18-25, Acts 8:40, 21:8, Acts 23:23 to end of chapter 26; Beck pp
            108-111
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Mt. Carmel:</span> I Kings 18:17-46,
            II Kings 4:25; Beck pp 220-222
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Jezreel Valley & Megiddo:</span> I
            Kings 9:15, II Kings 9:27 (II King 9), II Chronicles 35:20-25, II
            Kings 23:29-30; Beck 211-215
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Tiberias:</span> for information go to
            the following link on Bible Walks.
          </p>
        </div>
      ) : (
        ""
      )}

      {/* Day 2 */}
      {visiable[1] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[1]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>Mt. Arbel:</span> Matthew 4:12-23,
            Luke 8:1-3; Beck 215-218
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Sea of Galilee: </span> Matthew
            4:18-22, Matthew 8:23-27 (calming the sea) Matthew 14:22-36 (walking
            on water), Luke 5:1-11 (fishers of men), John 21:1-14 (post
            resurrection appearance); Beck 227-230
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Capernaum:</span> Mark 1:21-35,
            (demoniac healed and Peter’s mother in law),n Mark 2:1-5 (paralyzed
            man let down through the roof); Beck 185-189
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Mt. of Beatitudes:</span> Matthew 5-7,
            Luke 6:12, John 6:15; Beck 218-219
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Kursi (Gergesa):</span> Mark 5:1-20;
            Beck 209-211
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>
              Hippos/Sussita (Decapolis):
            </span>{" "}
            Mark 5:20, Matthew 5:14-15
          </p>
        </div>
      ) : (
        ""
      )}

      {/* Day 3 */}
      {visiable[2] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[2]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>Dan:</span> II Samuel 24:2 (Dan to
            Beersheba), Genesis 14:13-16, Judges 18:27-29, I Kings 12:26-33;
            Beck 189-193
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Caesarea Philippi:</span> Matthew
            16:13-18; Beck 179-181
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Mt Hermon:</span> Matthew 17:1-8,
            Psalm 133; Beck
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Valley of Tears:</span>{" "}
            <a {...links[0]} rel="norferrer" className={styles.link}>
              Israel Inside Out
            </a>
          </p>
        </div>
      ) : (
        ""
      )}

      {/* Day 4 */}
      {visiable[3] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[3]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>Harod&apos;s Spring: </span> Judges 7;
            Beck 193-195
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Nazareth Brow and Village:</span> Luke
            1:26-38, Luke 2:39-52, Luke 4:14-31, Luke 7:11-17 (Nain); Beck
            224-227
          </p>
        </div>
      ) : (
        ""
      )}
      {/* Day 5 */}
      {visiable[4] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[4]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>Jordan River: </span> Matthew 3:13-17
            (baptism of Jesus), Joshua 3 (crossing of the Jordan), II Kings
            5:13-14 (healing of Naaman)
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Jericho:</span> Joshua 6 (conquering
            of the city), 2 Kings 2:19-22 (healing of the water), Luke 18:35-43
            (healing of the blind man), Luke 19:1-10 (story of Zacchaeus); Beck
            141-144
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Qumran:</span> Beck 153-155
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>The Dead Sea:</span> Beck 126-127
          </p>
        </div>
      ) : (
        ""
      )}

      {/* Day 6 */}
      {visiable[5] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[5]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>Masada: </span> Beck 149-152
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Jerusalem:</span> Beck 41-44
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>The Broad Wall:</span> II Chronicles
            32:1-5; Beck 48-49
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Western Wall:</span> Beck 81-84
          </p>
        </div>
      ) : (
        ""
      )}

      {/* Day 7 */}
      {visiable[6] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[6]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>The Temple Mount: </span> Genesis
            22:9-14 (Abraham offers Isaac), II Samuel 24 :18-25 (David buys the
            ground for the Temple), 2 Chronicles 3:1 (Solomon builds the first
            temple), John 2 :13-16 (Herod’s temple that Jesus cleansed) ; Beck
            73-76
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Pool of Bethesda:</span> John 5:1-9;
            Beck 46-47
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>City of David:</span> 2 Samuel 5:6-10
            (David conquers the city), 2 Samuel 11:2-4; Beck 55-62
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>
              Hezekiah’s Tunnel/Pool of Siloam:
            </span>{" "}
            II Chronicles 32:30 (Hezekiah digs the tunnel), John 9:1-11 (Healing
            of the man born blind) , II Kings 18-19 (story of Hezekiah and
            Sennacherib); Beck 60-61
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>
              Mt Zion (Tomb of David and The Upper Room):
            </span>{" "}
            Mark 14:13-26, John 13-17; Beck 77-79
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>
              Valley of Gehenna (Hinnom) and Akeldama:
            </span>{" "}
            I Chronicles 33:1-6 (Manasseh), Matthew 5:30, 10:28 (hell/Gehenna),
            Matthew 27:1-10 (Judas and the field of blood); Beck 44-46
          </p>
        </div>
      ) : (
        ""
      )}

      {/* Day 8 */}
      {visiable[7] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[7]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>Mt. of Olives: </span> Mark 11:1-25
            (cursing of the fig tree and teaching on prayer), Luke 19:41-44
            (Jesus wept over the city). Matthew 24-25 (the Olivet Discourse),
            Luke 24:50-53, Acts 1:9-12 (the ascension of Jesus), Zechariah
            14:2-8; Beck 94-101
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Gethsemane:</span> Matthew 26:36-57,
            Luke 22:39-54, John 18:1; Beck 95-96
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Kidron Valley: </span> II Chronicles
            24:20-25 (Zechariah’s Tomb); Beck 68-70
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>
              Gordon’s Calvary/ the Garden Tomb:
            </span>{" "}
            John 19:16-18, 38-42; Beck 63-64
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>
              Church of the Holy Sepulcher:
            </span>{" "}
            Beck 51-54;
          </p>
        </div>
      ) : (
        ""
      )}

      {/* Day 9 */}
      {visiable[8] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[8]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>Bethlehem: </span> Genesis 35:16-20
            (Rachel’s tomb), book of Ruth (Boaz and Ruth), 1 Samuel 16:1-13,
            17:12 (hometown of David; he is anointed here) Micah 5:2, (Prophecy
            that Messiah will be born here) Luke 2:1-14 (Jesus is born here;
            Shepherds visit the infant Jesus), Matthew 2:1-12 (The wise men
            visit here); Beck 87-90
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>The Red Sea and Eilat:</span> Exodus
            14, I Kings 9:26 (place where Solomon built ships).
          </p>
        </div>
      ) : (
        ""
      )}
      {/* Day 10 */}
      {visiable[9] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[9]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>
              Timna Park/replica of the Tabernacle:{" "}
            </span>{" "}
            Exodus 25-30; Beck 156-157
          </p>
        </div>
      ) : (
        ""
      )}
      {/* Day 11 */}
      {visiable[10] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[10]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>Petra: </span>{" "}
            <a {...links[1]} rel="norferrer" className={styles.link}>
              Wikipedia - Petra
            </a>
            <a {...links[2]} rel="norferrer" className={styles.link}>
              Lonely Planet - Petra
            </a>
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>
              Was the Apostle Paul at Petra?{" "}
            </span>{" "}
            <a {...links[3]} rel="norferrer" className={styles.link}>
              Biblical Archaeology
            </a>
          </p>
        </div>
      ) : (
        ""
      )}

      {/* Day 12 */}
      {visiable[11] ? (
        <div className={styles.readingBox}>
          <p className={styles.readingComment}>{reading[11]}</p>
          <p className="text">
            {" "}
            <span className={styles.city}>Valley of Elah: </span> I Samuel 17;
            Beck 127-129
          </p>
          <p className="text">
            {" "}
            <span className={styles.city}>Joppa/Jaffa: </span> Jonah 1:3, Acts
            9:36-43, Acts 10, II Chronicles 2:16; Beck 112-113
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default Scriptureguide;
